import { useState, useEffect } from "react";

export default function ToggleButton(props) {
  const [isChecked, setIsChecked] = useState(localStorage.getItem('lang') || 'en');
    useEffect(()=>{
        localStorage.setItem('lang', isChecked=='en' ? 'en' : 'hn')
        props.predChange()
    }, [isChecked])
  return (
    <>
    <span style={{color: '#666', marginRight: 5, fontFamily: 'verdana'}}>HN</span>
    <label class="switch">
  <input type="checkbox" onClick={()=>setIsChecked(isChecked=='en' ? 'hn' : 'en')} checked={isChecked=='en'} />
  <span class="slider round"></span>
</label>
<span style={{color: '#666', marginLeft: 5, fontFamily: 'verdana'}}>EN</span>
</>
  );
}