import React, {useState} from 'react'
import Info from './Info'
import {Row, Col} from 'react-bootstrap';
import * as Global from './global/Global';
function Chart() {
// const [pred, setPred]=useState(0);
// const changePred=()=>{
//     setPred(pred*-1);
// }
    return (
//         <Row>
//             <Col sm={4} xs={12}>
                
// <Info predChange={()=>changePred()}/>
//             </Col>
//             <Col>
<React.Fragment>
<p>
                    {localStorage.getItem('lang')=='en' ?
                    <>
                <p>Loshu is a way to reveal secretes of your life using your date of birth. We analyse date of birth with
            different aspects and know which side of our personality is strong and where we need to work. If we analyse 
            correctly we can remove many hurdles of our life with simple steps as remedy.</p>
            <p>Every digit in your chart represents different planet, nature and behavior</p>
            <p><b>How Digits are arrange in your chart: </b>Digits are placed in the chart from date of birth ignoring 0.  
                If a digit is appearing multiple times or missing in your chart then it is called DOSHA in your chart.</p>
                <p><b>Remedies: </b>You can do simple and effective Remedy for your missing digits but it is not advisable to do remedy for all missing digits.</p>
            </>
            :
            <>
            <p>लोषु अंक ज्योतिष आपके जीवन के रहस्यों को उजागर करने का एक तरीका है, जो आपकी जन्मतिथि के माध्यम से किया जाता है। हम जन्मतिथि का विभिन्न दृष्टिकोणों से विश्लेषण करते हैं और यह समझते हैं कि हमारे व्यक्तित्व का कौन सा पक्ष मजबूत है और किन क्षेत्रों में सुधार की आवश्यकता है। यदि हम इसे सही तरीके से विश्लेषण करें, तो सरल उपायों के माध्यम से अपने जीवन की कई बाधाओं को दूर कर सकते हैं।</p>
                <p>हर अंक आपके चार्ट में एक विशेष ग्रह, स्वभाव और व्यवहार का प्रतिनिधित्व करता है।</p>

<p><b>चार्ट में अंक कैसे रखे जाते हैं? </b>
जन्मतिथि से 0 को हटा दिया जाता है और शेष अंकों को चार्ट में रखा जाता है।
अगर कोई अंक चार्ट में कई बार आता है या बिल्कुल नहीं आता, तो इसे दोष (Dosha) कहा जाता है।</p>

<p><b>दोष के उपाय (Remedy)</b>
आप अपने चार्ट में गायब अंकों के लिए सरल और प्रभावी उपाय कर सकते हैं। हालांकि, सभी गायब अंकों के लिए उपाय करना उचित नहीं होता।</p>
            </>
}
            </p>
            {
                localStorage.getItem('dd')!=='' && localStorage.getItem('mm')!=='' && localStorage.getItem('yyyy')!=='' ?
            <p className="prediction">
                
            <p style={{ border:'1px #ccc solid', borderRadius:'25px', padding: '25px'}}>
                <h3 style={{textAlign:'center', textDecoration:'underline'}}>{localStorage.getItem('lang')=='en' ? 'What your number suggests ?' : 'आपकी संख्या क्या संकेत देती है ? ' }</h3>
                <h5>{localStorage.getItem('lang')=='en' ? 'For Today' : 'आज के लिए' }:</h5>
                {Global.getPersonalDatePrediction()}
                <h5>{localStorage.getItem('lang')=='en' ? 'For This Month' : 'इस महीने के लिए'}:</h5>
                {Global.getPersonalMonthPrediction()}
                <h5>{localStorage.getItem('lang')=='en' ? 'For This Year' : 'इस वर्ष के लिए'}:</h5>
                <p>{Global.getPersonalYearPrediction()}</p>
                <p>{Global.getPersonalYearBestFor()}</p>
                <p>{Global.getPersonalYearChallenges()}</p>
            </p>
                <h5 className="predshead">{localStorage.getItem('lang')=='en' ? 'Birth Date Number' : 'जन्मांक संख्या'} ({Global.getBirthDate()[1]}): </h5>
                {localStorage.getItem('lang')=='en' ? <span>Your Birthdate number is your actual personality.</span> : <span>आपकी जन्मतिथि संख्या (Birthdate Number) आपके वास्तविक व्यक्तित्व का प्रतिनिधित्व करती है।</span>} {Global.getBirthDate()[1]} tells: 
                {Global.getBirthdatePreds()}
                <h5 className="predshead">{localStorage.getItem('lang')=='en' ? 'Life Path Number' : 'भाग्यांक संख्या' } ({Global.getLifePath()[1]}): </h5>
                {localStorage.getItem('lang')=='en' ? 
                'Your Lifepath number is the way how your life will be driven.' :
                'आपकी जीवन पथ संख्या यह दर्शाती है कि आपका जीवन किस दिशा में संचालित होगा।' } {Global.getLifePath()[1]} tells: 
                {Global.getLifepathPreds()}
                <p>{Global.getCombo()}</p>
                {/* <h5 className="predshead">Chart Numbers</h5> */}
                <hr />
                {Global.getChartPreds()}
            </p>
            :
            ''
}
{console.log(Global.getUniqueGridDigit())}
            </React.Fragment>)
            {/* {Global.getGridDigits()} */}
        //     </Col>
        // </Row>
    // )
}

export default Chart
