import React from 'react'
import * as Global from './global/Global';

function Planes() {
    const getFrequency=(v)=>{
        let f=0;
        return localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" && Global.getGridDigits().split("").filter(c=>{return c===v && v}).join(", ").toString();
    }
    return (
        localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" ?
        <React.Fragment>
            <table className="gridtable">
                <tr><th></th><th style={{textAlign:'center'}}>{localStorage.getItem('lang')=='en' ? 'Thought' : 'विचार'} {Global.getThought()}%</th><th style={{textAlign:'center'}}>{ localStorage.getItem('lang')=='en' ? 'Will' : 'संकल्प'} {Global.getWill()}%</th><th style={{textAlign:'center'}}>{ localStorage.getItem('lang')=='en' ? 'Action' : 'कार्य'} {Global.getAction()}%</th></tr>
<tr>
    <th style={{textAlign:'right'}}>{localStorage.getItem('lang')=='en' ? 'Mind' : 'मन' } {Global.getMind()}%</th>
    <td> {Global.getGridDigits().split("").includes("4") && getFrequency("4")}</td>
    <td> {Global.getGridDigits().split("").includes("9") && getFrequency("9")}</td>
    <td> {Global.getGridDigits().split("").includes("2") && getFrequency("2")}</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>{localStorage.getItem('lang')=='en' ? 'Soul' : 'आत्मा' } {Global.getSoul()}%</th>
    <td> {Global.getGridDigits().split("").includes("3") && getFrequency("3")}</td>
    <td> {Global.getGridDigits().split("").includes("5") && getFrequency("5")}</td>
    <td> {Global.getGridDigits().split("").includes("7") && getFrequency("7")}</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>{localStorage.getItem('lang')=='en' ? 'Practical' : 'व्यावहारिक' } {Global.getPractical()}%</th>
    <td> {Global.getGridDigits().split("").includes("8") && getFrequency("8")}</td>
    <td> {Global.getGridDigits().split("").includes("1") && getFrequency("1")}</td>
    <td> {Global.getGridDigits().split("").includes("6") && getFrequency("6")}</td>
    </tr>
                </table>
                <p className="prediction">
                    {localStorage.getItem('lang')=='en' ?
                    'In your chart all numbers participate in making your capability in different aspect like <strong>Thought</strong>, <strong>Will</strong>, <strong>Action</strong>, <strong>Mind</strong>, <strong>Soul</strong>, <strong>Practical</strong>, presence and absence of any number suggest your strong and weaker areas. You can think which area you need to do some remedy to make it strong.' :
                    'आपके चार्ट में सभी अंक विभिन्न पहलुओं में आपकी क्षमता बनाने में भाग लेते हैं, जैसे विचार, इच्छाशक्ति, कर्म, मन, आत्मा, व्यावहारिकता। किसी अंक की उपस्थिति या अनुपस्थिति आपके मजबूत और कमजोर क्षेत्रों का संकेत देती है। आप विचार कर सकते हैं कि किस क्षेत्र को मजबूत बनाने के लिए आपको कोई उपाय करने की आवश्यकता है।'
                    }
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Mind Plane {Global.getMind()}%</h5>
                    {
                        localStorage.getItem('lang')=='en' ?
                        'The Mind Plane talks about thinking capacity of the person. It reflects the powers of memory, intellect, analyzing capability, and thought.':
                        'Mind Plane व्यक्ति की सोचने की क्षमता को दर्शाता है। यह स्मरण शक्ति, बुद्धि, विश्लेषण करने की क्षमता और विचार शक्ति को प्रतिबिंबित करता है।'
                    }
                    
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Soul Plane {Global.getSoul()}%</h5>
                    {
                        localStorage.getItem('lang')=='en' ?
                        'It reflects sensitivity, freedom, love, artistic inclination, spiritual independence and intuition.':
                        'यह संवेदनशीलता, स्वतंत्रता, प्रेम, कला की ओर झुकाव, आध्यात्मिक स्वतंत्रता और अंतर्ज्ञान को प्रतिबिंबित करता है।'
                    }
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Practical Plane {Global.getPractical()}%</h5>
                    {
                        localStorage.getItem('lang')=='en' ?
                        'This plane represents practical abilities, organizational skills, materialistic abilities, verbal expression and the ability to do physical specially with one’s hand.':
                        'यह तल व्यावहारिक क्षमताओं, संगठनात्मक कौशल, भौतिकवादी क्षमताओं, मौखिक अभिव्यक्ति और विशेष रूप से हाथों से कार्य करने की क्षमता को दर्शाता है।'
                    }
                    
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Thought Plane {Global.getThought()}%</h5>
                    {
                        localStorage.getItem('lang')=='en' ?
                        'It reflects the ability of a person to think about new ideas. It also reflects the person’s love of order and method. This plane is therefore even referred to as ‘The Plane of the Planner’.':
                        'यह किसी व्यक्ति की नई विचारों के बारे में सोचने की क्षमता को दर्शाता है। यह व्यक्ति के क्रम और विधि के प्रति प्रेम को भी दर्शाता है। इसलिए इस तल को ‘योजना निर्माता का तल’ भी कहा जाता है।'
                    }
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Will Plane {Global.getWill()}%</h5>
                    {
                        localStorage.getItem('lang')=='en' ?
                        'It reflects a person’s determination and will to succeed. It also shows a person’s patience and persistence in realizing his goals.':
                        'यह किसी व्यक्ति के संकल्प और सफलता प्राप्त करने की इच्छा को दर्शाता है। यह उसके धैर्य और अपने लक्ष्यों को प्राप्त करने की दृढ़ता को भी दर्शाता है।'
                    }
                    
                </p>
                <p className='prediction'>
                    <h5 className='predshead'>Action Plane {Global.getAction()}%</h5>
                    {
                        localStorage.getItem('lang')=='en' ?
                        'It revolves around the individual’s ability to put his ideas and ability in action and execute them.':
                        'यह व्यक्ति की अपनी विचारधारा और क्षमताओं को क्रियान्वित करने और उन्हें लागू करने की क्षमता को दर्शाता है।'
                    }
                </p>
        </React.Fragment>
        :
        <p>
            <table className="gridtable">
                <tr><th></th><th style={{textAlign:'center'}}>Thought</th><th style={{textAlign:'center'}}>Will</th><th style={{textAlign:'center'}}>Action</th></tr>
<tr>
    <th style={{textAlign:'right'}}>Mind</th>
    <td>4</td>
    <td>9</td>
    <td>2</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>Soul</th>
    <td>3</td>
    <td>5</td>
    <td>7</td>
    </tr>
<tr>
    <th style={{textAlign:'right'}}>Practical</th>
    <td>8</td>
    <td>1</td>
    <td>6</td>
    </tr>
                </table>
                {
                    localStorage.getItem('lang')=='en' ?
                    'Each row and column represents different ability of your personality you can analyse which part is strong and where you need some support. ':
                    'प्रत्येक पंक्ति और स्तंभ आपके व्यक्तित्व की विभिन्न क्षमताओं को दर्शाता है। आप यह विश्लेषण कर सकते हैं कि कौन सा पक्ष मजबूत है और कहाँ आपको समर्थन की आवश्यकता है।'
                }
            
            <p>
            {
                localStorage.getItem('lang')=='en' ?
                'You will be amazed by seeing the result and it is really helpful. You should try it, just fill your data.':
                'आप परिणाम देखकर चकित रह जाएंगे, और यह वास्तव में सहायक है। आपको इसे अवश्य आज़माना चाहिए, बस अपना डेटा भरें।'
            }
                </p>
        </p>
    )
}

export default Planes
