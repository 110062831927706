import PredsEN from './Preds.json';
import PredsHN from './HindiPreds.json';
const Preds = () => localStorage.getItem('lang') && localStorage.getItem('lang')=='hn' ? PredsHN : PredsEN;
const KarmicDebtNumber=[13,14,16,19];
const MasterNumber=[11,22,33];
const AntiNumber=[[8],[4,8,9],[6],[2,9],[],[3],[],[1,2],[2,4]]
const getBirthDate=()=>singleDigit(localStorage.getItem('dd'));
const getLifePath=()=>singleDigit(localStorage.getItem('dd')+localStorage.getItem('mm')+localStorage.getItem('yyyy'));
const getKuaNumber=()=>{return 0};
const getGridDigits=()=>{
    return localStorage.getItem('dd')!=="" && localStorage.getItem('mm')!=="" && localStorage.getItem('yyyy')!=="" ?(localStorage.getItem('dd')+localStorage.getItem('mm')+localStorage.getItem('yyyy')+(Number(localStorage.getItem('dd'))>9 ? getBirthDate()[1] : 0)+getLifePath()[1]+kuaNumber()).replace('0', '') : '0' ;
    // return localStorage.getItem('dd')!==undefined && localStorage.getItem('mm')!==undefined && localStorage.getItem('yyyy')!==undefined && localStorage.getItem('dd')+localStorage.getItem('mm')+localStorage.getItem('yyyy')+getBirthDate()[1]+getLifePath()[1];
};
const kuaNumber=(year=localStorage.getItem('yyyy'), gender=localStorage.getItem('gender'))=>{
    // console.log(year);
    if(!(year===undefined && gender===undefined)){ 
    switch(gender){
        case 'Male':
            return singleDigit(11-singleDigit(year)[1])[1];
        case 'Female':
            return singleDigit(4+singleDigit(year)[1])[1];
        case 'Other':
            return singleDigit(8+singleDigit(year)[1])[1];
    }
}
return '';
}
const personalDate=()=>{
    return singleDigit(personalMonth()+new Date().getDate())[1];
}
const personalMonth=()=>{
return singleDigit(personalYear()+new Date().getMonth()+1)[1];
}
const personalYear=()=>{
let d=localStorage.getItem('dd');
let m=localStorage.getItem('mm');
let currentYear = new Date().getFullYear();
return singleDigit(d+m+currentYear)[1];
}
const singleDigit=(num)=>{
    let ans=[0,0];
    if(num){
    let d;
    let sum=0;
    while(true){
        if(num<10){
            ans[1]=num;
            break;
        }
        if(KarmicDebtNumber.includes(num))
            ans[0]=num;
        if(MasterNumber.includes(num))
            ans[0]=num;
        d=num.toString().split('');
        sum=0;
        d.map(d1=>(sum+=Number(d1)));
        num=sum;
    }
}
    return ans;
}    
const getThought=()=>{
    let s=0;
    s+=getUniqueGridDigit().includes('4') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('3') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('8') ? 33 : 0;
    return s>=90 ? 100 : s 
}
const getWill=()=>{
    let s=0;
    s+=getUniqueGridDigit().includes('1') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('5') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('9') ? 33 : 0;
    return s>=90 ? 100 : s 
}
const getAction=()=>{
    let s=0;
    s+=getUniqueGridDigit().includes('2') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('7') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('6') ? 33 : 0;
    return s>=90 ? 100 : s 
}
const getMind=()=>{
    let s=0;
    s+=getUniqueGridDigit().includes('4') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('1') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('2') ? 33 : 0;
    return s>=90 ? 100 : s 
}
const getSoul=()=>{
    let s=0;
    s+=getUniqueGridDigit().includes('3') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('5') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('7') ? 33 : 0;
    return s>=90 ? 100 : s 
}
const getPractical=()=>{
    let s=0;
    s+=getUniqueGridDigit().includes('8') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('9') ? 33 : 0; 
    s+=getUniqueGridDigit().includes('6') ? 33 : 0;
    return s>=90 ? 100 : s 
}
const getPersonalYearPrediction = () => Preds().personalYear[singleDigit(personalYear())[1]].prediction;
const getPersonalYearBestFor = () => Preds().personalYear[singleDigit(personalYear())[1]].bestFor;
const getPersonalYearChallenges = () => Preds().personalYear[singleDigit(personalYear())[1]].challenges;
const getPersonalMonthPrediction = () => Preds().personalMonth[singleDigit(personalMonth())[1]];
const getPersonalDatePrediction = () => Preds().personalDate[singleDigit(personalDate())[1]];
const getCombo=()=>Preds().combo[getBirthDate()[1]+"-"+getLifePath()[1]];
const getUniqueGridDigit=()=>[...new Set(getGridDigits().split(""))].filter((x)=>x!=="0");
const getOccurrence=(n)=>getGridDigits().split(n).length-1<=3 ? getGridDigits().split(n).length-1 : 3;
const getChartPreds=()=>getUniqueGridDigit().map((x)=><p className="predictions"><h5>{localStorage.getItem('lang')=='en' ? `Prediction for ${x}` : `${x} के लिए भविष्यवाणी`}</h5>{Preds().chart[x.repeat(getOccurrence(x))]}</p>);
const getMissingPreds=()=>['1','2','3','4','5','6','7','8','9'].filter(d=>!getUniqueGridDigit().includes(d)).map((x)=><p className="predictions"><h5 className='predshead'>Missing Digit: {x}</h5>{Preds().missing[x]}</p>);
const getRemedy=()=>['1','2','3','4','5','6','7','8','9'].filter(d=>!getUniqueGridDigit().includes(d)).map((x)=><p className="predictions"><h5 className='predshead'>Missing Digit: {x}</h5>{Preds().remedy[x]}</p>);
const getBirthdatePreds=()=>Preds().number[getBirthDate()[1]];
const getLifepathPreds=()=>Preds().number[getLifePath()[1]];
// const isMobileLucky=(x)=>AntiNumber[(getBirthDate()[1]-1)];
// const isMobileLucky=(x)=>x.split("").reduce((s,n)=>s+=Number(n),0);
const isMobileLucky=(x)=>!AntiNumber[(getBirthDate()[1]-1)].includes(singleDigit(x.split("").reduce((s,n)=>s+=Number(n),0))[1]);
const mobilePreds=(x)=>Preds().mobile[x];
const getKarmicDebtNumber=()=>KarmicDebtNumber.join(", ");
export {getPersonalYearPrediction, getPersonalYearBestFor, getPersonalYearChallenges, getPersonalMonthPrediction, getPersonalDatePrediction, personalDate, personalMonth, personalYear, getBirthDate,getCombo,mobilePreds,getKarmicDebtNumber,isMobileLucky,getLifePath,getRemedy,getThought,getMissingPreds,getWill,getAction,getUniqueGridDigit,getMind,getSoul,getPractical,singleDigit,getGridDigits,getLifepathPreds,kuaNumber,getBirthdatePreds,getChartPreds};