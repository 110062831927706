import React, {useState} from 'react';
import Styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import {Row, Col} from 'react-bootstrap';
import { Navbar,Nav,NavDropdown,Container } from 'react-bootstrap';
import logo from './loshu.png';
import {Routes, Route, BrowserRouter as Router, NavLink} from 'react-router-dom';
import Info from './components/Info'
import Loshu from './components/Loshu';
import Chart from './components/Chart';
import Missing from './components/Missing';
import Planes from './components/Planes';
import Heading from './components/Heading';
import Remedy from './components/Remedy';
import Mobile from './components/Mobile';
import ErrorBoundry from './components/ErrorBoundry';
import Map from './components/Map';
import ToggleButton from './components/ToggleButton';
const MainContainer = Styled.div`
display:flex;
width:100%;
flex-wrap:wrap;
justify-content:space-between;
`;
const LeftPanel=Styled.div`
width:25%;
box-shadow: 3px 3px 10px #ccc;
padding:5px;
max-height:100%;
${props=>props.expand && `max-height:35px; overflow:hidden;`}

@media (max-width: 820px){
  width:45%;
}
@media(max-width: 480px){
  width:100%;
}
`;
const RightPanel=Styled.div`
width:70%;
@media (max-width: 820px){
  width:45%;
}
@media(max-width: 480px){
  width:100%;
}
`;
const Expand=Styled.div`
  background-color: #0394fc;
  color:#fff;
  align-items:center;
  text-align:center;
  font-weight: 900;
  font-face: verdana;
  border-radius:5px;
  cursor:pointer;
`;
function App(props) {
  const [pred, setPred]=useState(0);
  const [expand, setExpand]=useState(true);
const changePred=()=>{
    setPred(pred*-1);
}
// const usePathname=()=> useLocation().pathname;

  return (
    <div>
      <Router>
      <div className="row">
                    <div className="col-md-12">
                    <Navbar bg="light" expand="lg" fixed="top">
  <Container>
    <Navbar.Brand><a href="https://loshugrid.info"><img src={logo} alt="Loshu Grid" width="50" /></a></Navbar.Brand>
    <span id="mobile">
        <ToggleButton predChange={()=>changePred()} />
        </span>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <NavLink to="/chart" className={active => active.isActive ? "activemenu" : ""}>Loshu Chart</NavLink>
        <NavLink to="/missing" className={active => active.isActive ? "activemenu" : ""}>Missing Numbers</NavLink>
        <NavLink to="/planes" className={active => active.isActive ? "activemenu" : ""}>Loshu Planes</NavLink>
        {/* <NavLink to="/vastu" className={active => active.isActive ? "activemenu" : ""}>Loshu Vastu</NavLink> */}
        <NavLink to="/mobile" className={active => active.isActive ? "activemenu" : ""}>Mobile Number</NavLink>
        <NavLink to="/remedy" className={active => active.isActive ? "activemenu" : ""}>Remedy</NavLink>
        {/* <NavLink to="/loshu" className={active => active.isActive ? "activemenu" : ""}>About Loshu</NavLink> */}
        {/* <Link to="/recommendation">Recommendation</Link> */}
        {/* <Link to="/print">Print</Link> */}
        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown> */}
        
      </Nav>
    </Navbar.Collapse>
    <span id="bigscreen">
        <ToggleButton predChange={()=>changePred()} />
        </span>
  </Container>
</Navbar><br /><br /><br />
<Container>
  <ErrorBoundry>
  <div className="notebook">
  {/* <Row> */}
  <MainContainer>
            {/* <Col sm={3} xs={12} style={{boxShadow:'3px 3px 10px #ccc ', padding:5}}> */}
               <LeftPanel expand={expand}> 
                <Expand onClick={()=>{console.log('expand', expand);setExpand(!expand)}}>New Entry</Expand>
<Info predChange={()=>changePred()}/>
</LeftPanel>
            {/* </Col> */}
            {/* <Col sm={6} xs={12}> */}
            <RightPanel>
            {<Heading text={localStorage.getItem('name')!=="" ? `Hello ${localStorage.getItem('name')}` : "Hello User ! Please fill your data to proceed."}  />}
<Routes>
  <Route path="/" element={<Chart />} />
  {/* <Route exact path="/loshu" element={<Loshu />} /> */}
  <Route exact path="/chart" element={<Chart />} />
  <Route exact path="/missing" element={<Missing />} />
  <Route exact path="/planes" element={<Planes />} />
  <Route exact path="/remedy" element={<Remedy />} />
  <Route exact path="/mobile" element={<Mobile />} />
  <Route path="" element={<Chart />} />
</Routes>
</RightPanel>
{/* </Col> */}
{/* <Col> */}
{/* <Map /> */}
{/* </Col> */}
</MainContainer>
{/* </Row> */}
<div className="footer">A unit of <a href="https://authenticastro.in">Authentic Astro.</a> <br />Personalised Prediction Contact: info@authenticastro.in</div>
</div>
</ErrorBoundry>
</Container>
                    </div>
                </div>
                </Router>
                {/* <footer className="footer"><Container>Personalised Prediction Contact: gridloshu@gmail.com</Container></footer> */}
    </div>
  );
}

export default App;
