import React, {useState} from 'react'
import {isMobileLucky, singleDigit, mobilePreds,getKarmicDebtNumber} from './global/Global';
function Mobile() {
    const [mobile, setMobile]=useState("9999999999");
    return (
        <React.Fragment>
            <p>Mobile numbers are our personal item which we carry with us always, so sometimes we think wheather our number is lucky for us or not.
                Your number should contain 1, 9, 7, 6 and digits from your birth date (only date). if the sum of the digits are coming from <span style={{color:'red'}}>{getKarmicDebtNumber()}</span> you should avoid the mobile number.
            </p>
            <h4>Enter Your Number</h4>
            <input type="text" value={mobile} onChange={(e)=>{Number(e.target.value)>=0 && setMobile(e.target.value)}} maxLength="10" className="form-control" />
            {/* {getKarmicDebtNumber().split(", ").includes(singleDigit(Number(mobile))[0].toString()).toString()} */}
            <h3>{mobile.split("").length===10 && (isMobileLucky(mobile) ? "Your mobile number is Lucky" : "Your mobile number is Not Favourable")}</h3>
            {mobile.split("").length===10 && <div>Sum of digits: <span style={{color:singleDigit(Number(mobile))[0]!==0 && getKarmicDebtNumber().split(", ").includes(singleDigit(Number(mobile))[0].toString()) && "red"}}>{mobile.split("").length===10 && (singleDigit(Number(mobile))[0]!==0 && singleDigit(Number(mobile))[0])}</span>
            {singleDigit(Number(mobile))[0]!==0 && " ==> "}
            {(singleDigit(Number(mobile))[1])}</div>}<br />
            {singleDigit(Number(mobile))[0]!==0 && getKarmicDebtNumber().split(", ").includes(singleDigit(Number(mobile))[0].toString()) && <h6 style={{color:'red'}}>Since total of the digits are being generated finally from {getKarmicDebtNumber()}, you should avoid this number.</h6>}
            {mobile.split("").length===10 && <p><h5>Prediction for sum of all digits:</h5>{mobilePreds(singleDigit(Number(mobile))[1])}</p>}
            {/* Other digits have these prediction:<br />
            {mobile==="9999999999" && [1,2,3,4,5,6,7,8,9].map(x=><p>{mobilePreds(x)}</p>)} */}
        </React.Fragment>
    )
}

export default Mobile
